<template>
  <div>
    <aAnchor @change="smoothScroll">
      <div class="title">Documentos</div>
      <draggable v-model="docsArr" @end="onEndDragOrderDocuments">
        <div v-for="(doc, index) in docsArr" :key="index">
          <aAnchorLink :href="`#doc-${doc.id}`">
            <template #title>
              <div class="dotted-phrase">
                <a-checkbox
                  class="mr-10"
                  v-model="doc.show"
                  style="font-size: 13px; font-weight: 500; position: relative"
                  @change="onCheckShowDocument"
                >
                </a-checkbox>
                <a-tooltip
                  v-if="doc.id == 91"
                  placement="top"
                  title="Ver dados do cartão"
                >
                  <a-button
                    type="primary"
                    class="mr-5"
                    @click="viewCreditCardData"
                    shape="circle"
                    size="small"
                    icon="eye"
                  />
                </a-tooltip>
                {{ doc.id }} - {{ doc.name }}
              </div>
            </template>
          </aAnchorLink>
        </div>
      </draggable>
    </aAnchor>

    <aForm
      v-if="editDocuments"
      :form="documentsForm"
      @submit="submitDocumentsForm"
    >
      <a-form-item class="mt-20 mb-10 contract-docs">
        <a-select
          class="travel-input f12"
          show-search
          placeholder="Selecione os documentos..."
          mode="multiple"
          v-decorator="[
            'contract_documents',
            {
              initialValue: selectedDocs,
              rules: [
                {
                  required: true,
                  message: 'Selecione os documentos que serão gerados.',
                },
              ],
            },
          ]"
        >
          <a-select-option
            v-for="(item, index) of documentsList"
            :key="index"
            :value="item.id"
          >
            #{{ item.id }} - {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <div class="a-center">
        <a-button
          :loading="loadingDocumentsForm"
          type="primary"
          ghost
          html-type="submit"
        >
          Salvar documentos
        </a-button>
      </div>
    </aForm>

    <aModal
      :visible="showPasswordModal"
      :footer="false"
      :width="300"
      @cancel="showPasswordModal = false"
    >
      <template #title> <aIcon type="lock" /> Verificação </template>
      <LoginFormModal
        v-if="showPasswordModal"
        :updateStorageData="false"
        @verificationSuccessfully="verificationSuccessfully"
      />
    </aModal>
  </div>
</template>

<script>
import jq from "jquery";
import draggable from "vuedraggable";
import documentMixins from "@/components/documents/mixins/documentMixins";
import LoginFormModal from "../../login/LoginFormModal.vue";

export default {
  name: "ViewContractDocNavSectionV2",
  props: {
    contract: Object,
    editDocuments: Boolean,
  },
  components: {
    draggable,
    LoginFormModal,
  },
  mixins: [documentMixins],
  data() {
    return {
      docsArr: [],
      documentsForm: this.$form.createForm(this),
      documentsList: [],
      showPasswordModal: false,
    };
  },
  mounted() {
    this.getDocuments();
    this.getSelectedDocuments();
  },
  created() {
    this.$on("openPasswordModal", this.handleEvent);
    this.$on("setDocuments", this.setDocuments);
  },
  methods: {
    getDocuments() {
      this.loadingDocuments = true;
      this.$http
        .get(
          `/document/list?page=1&per_page=1000&company_branch_id=${this.contract.company_branch_id}&status=1&is_general=1&html=no`
        )
        .then(({ data }) => {
          this.documentsList = data.data;
          this.loadingDocuments = false;
        });
    },
    getSelectedDocuments() {
      let documents =
        this.contract.meta.contract_documents != undefined
          ? JSON.parse(this.contract.meta.contract_documents)
          : [];

      if (documents.length) {
        this.loadingDocuments = true;
        this.$http
          .get(`/document/list?page=1&per_page=100&id=${documents}`)
          .then(({ data }) => {
            this.docsArr = data.data.map((doc) => {
              return { ...doc, check: true, show: true };
            });

            const sortedArr2 = this.docsArr.sort(
              (a, b) =>
                this.contract.meta.contract_documents.indexOf(a.id) -
                this.contract.meta.contract_documents.indexOf(b.id)
            );

            this.$emit("updateContractPages", sortedArr2);
            this.loadingDocuments = false;
          });
      }
    },
    submitDocumentsForm(e) {
      e.preventDefault();
      this.documentsForm.validateFields((err, values) => {
        if (!err) {
          this.loadingDocumentsForm = true;
          this.$http
            .post("/contract/update-documents", {
              contract_documents: JSON.stringify(values.contract_documents),
              id: this.contract.id,
              changed_by: this.$store.state.userData.id,
              modified_by: {
                name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
                id: this.$store.state.userData.id,
              },
            })
            .then(({ data }) => {
              this.$message.success(data.message);
              this.loadingDocumentsForm = false;
              window.open("/contracts/view/" + this.contract.id, "_self");
            });
        }
      });
    },
    viewCreditCardData() {
      this.showPasswordModal = true;
      this.userPassword = " ";
      setTimeout(() => {
        this.userPassword = "";
      }, 400);
    },
    verificationSuccessfully(verificationData) {
      this.showPasswordModal = false;
      this.getCardDataLoading = true;

      this.$hayaApi
        .post(`/contracts/credit-cards-data`, {
          ...verificationData,
          contractId: this.contract.id,
        })
        .then(({ data }) => {
          this.$message.success(data.message);

          this.contract.meta = {
            ...this.contract.meta,
            ...data.card,
          };

          this.$emit("updateContractMeta", this.contract.meta);
        })
        .finally(() => {
          this.getCardDataLoading = false;
        });
    },
    handleEvent() {
      this.showPasswordModal = true;
    },
    setDocuments() {
      setTimeout(() => {
        this.documentsForm.setFieldsValue({
          contract_documents: this.contract.meta.contract_documents
            ? JSON.parse(this.contract.meta.contract_documents)
            : [],
        });
      }, 300);
    },
    onEndDragOrderDocuments() {
      this.$emit("onEndDragOrderDocuments", this.docsArr);
    },
    onCheckShowDocument() {
      this.$emit("onCheckShowDocument", this.docsArr);
    },
    smoothScroll(e) {
      if (e) {
        let elementTop = document.getElementById(e.replace("#", "")).offsetTop;

        jq(".document-viewer").animate(
          {
            scrollTop: elementTop,
          },
          1000
        );
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.dotted-phrase
  text-transform: uppercase
  font-size: 12px
  font-weight: 500
  width: 260px
  padding: 1px 0 0
.title
  padding: 0 0 0 16px
  font-weight: 500
  font-size: 10px
  color: #ccc
</style>
