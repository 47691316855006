<template>
  <section class="bg pd-20">
    <aRow type="flex" align="top" :gutter="15">
      <aCol class="sidebar" flex="340px">
        <aRow :gutter="[20, 0]">
          <aCol class="mb-20" :span="24">
            <div class="w-box">
              <ViewContractDataSection
                :contract="contract"
                v-if="contract.id != undefined"
              />
              <div v-else>
                <a-skeleton active />
              </div>
            </div>
          </aCol>

          <aCol class="mb-20" :span="24">
            <div class="w-box">
              <ViewContractCustomerDataSection
                :contract="contract"
                v-if="contract.id != undefined"
              />
              <div v-else>
                <a-skeleton active />
              </div>
            </div>
          </aCol>

          <aCol class="mb-20" :span="24">
            <div class="w-box">
              <template v-if="parseInt(contract.id) > 6144">
                <ViewContractDocNavSectionV2
                  ref="ViewContractDocNavSectionV2"
                  v-if="contract.company_branch_id != undefined"
                  :contract="contract"
                  :editDocuments="editDocuments"
                  @updateContractPages="updateContractPagesV2"
                  @onCheckShowDocument="onCheckShowDocument"
                  @onEndDragOrderDocuments="onEndDragOrderDocuments"
                  @updateContractMeta="updateContractMeta"
                />
              </template>

              <template v-if="parseInt(contract.id) < 6145">
                <ViewContractDocNavSection
                  v-if="contract.company_branch_id != undefined"
                  :contract="contract"
                  :editDocuments="editDocuments"
                  :contractPages="contractPages"
                  @updateContractPages="updateContractPages"
                  @updateContractMeta="updateContractMeta"
                  ref="ViewContractDocNavSection"
                />
                <div v-else>
                  <a-skeleton active />
                </div>
              </template>
            </div>
          </aCol>

          <aCol
            v-if="contract.id != undefined"
            class="actions mb-20"
            :span="24"
          >
            <aRow :gutter="20">
              <aCol :span="6">
                <aButton
                  :disabled="contract.status === 'concluded' ? false : true"
                  class="edit"
                  @click="onClickEditDocuments"
                  ghost
                  ><img
                    v-if="!editDocuments"
                    src="@/assets/images/dashboard/contracts/graphic-design.png"
                    alt="ico"
                  />

                  <a-icon v-if="editDocuments" type="close" />
                </aButton>
              </aCol>

              <aCol :span="18">
                <aButton
                  :disabled="contract.status === 'concluded' ? false : true"
                  class="download"
                  @click="onClickGeneratePdf"
                  :loading="generatePDFLoading"
                >
                  <a-icon v-if="!generatePDFLoading" type="download" />
                  download
                </aButton>
              </aCol>
            </aRow>
          </aCol>

          <aCol class="mb-20 obs" :span="24">
            <div>
              <span class="red">Atenção:</span> verifique todos os dados do
              contrato antes de enviá-lo ao cliente.
            </div>
          </aCol>
        </aRow>
      </aCol>

      <aCol class="content" flex="auto">
        <div class="w-box document-viewer">
          <div v-if="isDraggingDocuments" class="a-center">
            <a-icon
              type="loading"
              style="font-size: 200px; color: #fff; margin-top: 100px"
            />
          </div>

          <template v-if="parseInt(contract.id) > 6144">
            <ViewContractPreviewDocumentsSectionV2
              v-if="!isDraggingDocuments && contract.meta != undefined"
              :contract="contract"
              :documents="documentsArr"
            />
          </template>

          <template v-if="parseInt(contract.id) < 6145">
            <ViewContractPreviewDocumentsSection
              v-if="contract.meta != undefined"
              :contract="contract"
              :contrato="contrato"
              :contractPages="contractPages"
              @updateContractPages="updateContractPages"
            />
          </template>
        </div>
      </aCol>
    </aRow>
  </section>
</template>

<script>
import axios from "axios";
import formatThings from "@/mixins/general/formatThings";
import generateContractPtBr from "@/components/contracts/mixins/generateContractPtBr";

import ViewContractDataSection from "@/components/contracts/sections/ViewContractDataSection.vue";
import ViewContractCustomerDataSection from "@/components/contracts/sections/ViewContractCustomerDataSection.vue";
import ViewContractDocNavSection from "@/components/contracts/sections/ViewContractDocNavSection.vue";
import ViewContractDocNavSectionV2 from "@/components/contracts/sections/ViewContractDocNavSectionV2.vue";
import ViewContractPreviewDocumentsSection from "@/components/contracts/sections/ViewContractPreviewDocumentsSection.vue";
import ViewContractPreviewDocumentsSectionV2 from "@/components/contracts/sections/ViewContractPreviewDocumentsSectionV2.vue";

export default {
  name: "ViewContractPageV2",
  components: {
    ViewContractDataSection,
    ViewContractCustomerDataSection,
    ViewContractDocNavSection,
    ViewContractDocNavSectionV2,
    ViewContractPreviewDocumentsSection,
    ViewContractPreviewDocumentsSectionV2,
  },
  mixins: [formatThings, generateContractPtBr],
  data() {
    return {
      contract: {},
      loadingContract: false,
      editDocuments: false,
      generatePDFLoading: false,
      contractPages: [],
      documentsArr: [],
      isDraggingDocuments: false,
    };
  },
  mounted() {
    this.getContract();
  },
  computed: {
    routeTab() {
      return {
        title: `Contrato ${this.contract.id ? this.contract.id : ""}`,
        icon: "eye",
        tips: "",
      };
    },
  },
  methods: {
    onCheckShowDocument(docsArr) {
      this.documentsArr = docsArr;
    },
    onEndDragOrderDocuments(docsArr) {
      this.isDraggingDocuments = true;
      this.documentsArr = docsArr;
      setTimeout(() => {
        this.isDraggingDocuments = false;
      }, 100);
    },
    onClickGeneratePdf() {
      const documentsWrapper = document.getElementById("the-outer-document");
      const outerHTMLContent = documentsWrapper.outerHTML;

      if (this.contrato.pagamento.credito.ativo) {
        let cartoesCredito = this.contrato.pagamento.credito.cartao,
          isOnlinePayment = cartoesCredito.filter((cartao) => {
            return cartao.pagamento_online == 1;
          });

        if (
          isOnlinePayment.length === 0 &&
          !this.contract.meta["payment_methods_credit_card_client_name_1"] &&
          !this.contract.meta["payment_methods_credit_card_client_month_1"] &&
          !this.contract.meta["payment_methods_credit_card_client_year_1"]
        ) {
          if (parseInt(this.contract.id) < 6145)
            this.$refs.ViewContractDocNavSection.$emit("openPasswordModal");

          if (parseInt(this.contract.id) > 6144)
            this.$refs.ViewContractDocNavSectionV2.$emit("openPasswordModal");

          this.$message.warning(
            "Você está gerando o PDF sem exibir os dados de cartão do cliente. Insira sua senha para exibir os dados."
          );
          return;
        }
      }

      this.generatePDFLoading = true;

      axios
        .post(
          `https://integrations.haya.net.br/v1/integrations/generate-pdf`,
          {
            html: btoa(
              unescape(
                encodeURIComponent(
                  outerHTMLContent
                    .replace("v-style", "style")
                    .replace("v-style", "style")
                )
              )
            ),
          },
          {
            responseType: "arraybuffer",
          }
        )
        .then(({ data }) => {
          const blob = new Blob([data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);

          // Specify the filename for the download
          link.download = `${this.contract.id} -  CONTRATO DE VIAGENS - ${this.contract.customer.first_name} ${this.contract.customer.last_name}.pdf`;

          // Append the link to the document
          document.body.appendChild(link);

          // Trigger the click event to start the download
          link.dispatchEvent(new MouseEvent("click"));

          // Remove the link from the document
          document.body.removeChild(link);

          this.generatePDFLoading = false;
        });
    },
    getContract() {
      this.loadingContract = true;
      this.$http
        .post(`/contract/details`, {
          id: this.$route.params.id,
        })
        .then(({ data }) => {
          this.contract = data;
          document.title = `${data.id} - ${this.customerName()}`;

          if (!this.$root.isAdmin() && !this.$root.isAnalyst())
            if (data.user.id != this.$store.state.userData.id)
              this.$tabs.close(`/contracts/view/${data.id}`, `/contracts/list`);

          setTimeout(() => {
            this.generateContractPtBr(data);
          }, 200);

          this.loadingContract = false;
        });
    },
    onClickEditDocuments() {
      if (this.editDocuments) {
        this.editDocuments = false;
      } else {
        this.editDocuments = true;

        this.$refs.ViewContractDocNavSectionV2.$emit("setDocuments");
      }
    },
    customerName() {
      let theName = "";
      if (this.contract.customer != undefined) {
        if (this.contract.customer.person_type == "Pessoa Física") {
          theName = `${this.contract.customer.first_name} ${this.contract.customer.last_name}`;
        } else {
          theName = `${this.contract.customer.trading_name}`;
        }
      }
      return theName;
    },
    updateContractPagesV2(documentsArr) {
      this.documentsArr = documentsArr;

      this.isDraggingDocuments = true;
      setTimeout(() => {
        this.isDraggingDocuments = false;
      }, 100);
    },
    updateContractPages(documentsArr) {
      this.contractPages = documentsArr;
    },
    updateContractMeta(meta) {
      this.contract.meta = meta;
      let creditCards =
        meta.credit_card_rows != undefined
          ? JSON.parse(meta.credit_card_rows)
          : [];

      if (creditCards.length > 0) {
        creditCards.forEach((card, index) => {
          let onlinePayment =
            meta[`payment_methods_credit_card_is_payment_online_${card.id}`] !=
            undefined
              ? meta[`payment_methods_credit_card_is_payment_online_${card.id}`]
              : 0;

          if (onlinePayment != 1) {
            this.contrato.pagamento.credito.cartao[index].expiracao = `${
              meta[`payment_methods_credit_card_client_month_${card.id}`]
            } / ${
              meta[`payment_methods_credit_card_client_year_${card.id}`]
                ? meta[
                    `payment_methods_credit_card_client_year_${card.id}`
                  ].slice(-2)
                : ""
            }`;

            this.contrato.pagamento.credito.cartao[index].cvv = `${
              meta[`payment_methods_credit_card_client_cvv_${card.id}`] !=
              undefined
                ? meta[`payment_methods_credit_card_client_cvv_${card.id}`]
                : ""
            } `;

            this.contrato.pagamento.credito.cartao[index].nome = `${
              meta[`payment_methods_credit_card_client_name_${card.id}`]
            } `.toUpperCase();

            this.contrato.pagamento.credito.cartao[index].bandeira = `${meta[
              `payment_methods_credit_card_client_flag_${card.id}`
            ].trim()}`;

            this.contrato.pagamento.credito.cartao[index].numeros.primeiro =
              meta[
                `payment_methods_credit_card_client_number_${card.id}`
              ].split(" ")[0];

            this.contrato.pagamento.credito.cartao[index].numeros.segundo =
              meta[
                `payment_methods_credit_card_client_number_${card.id}`
              ].split(" ")[1];

            this.contrato.pagamento.credito.cartao[index].numeros.terceiro =
              meta[
                `payment_methods_credit_card_client_number_${card.id}`
              ].split(" ")[2];

            this.contrato.pagamento.credito.cartao[index].numeros.quarto =
              meta[
                `payment_methods_credit_card_client_number_${card.id}`
              ].split(" ")[3];
          }
        });
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.view-contract
  min-height: 91vh
.content
  .w-box
    padding: 50px 0px!important
    background: #ccc
    height: 80vh
    overflow: auto
    box-shadow: inset 0 0 100px #929292
    border-radius: 8px
    border: 1px solid #ddd
.sidebar
  .actions
    .edit
      color: #333
      border: 2px solid #5bcb95
    .download
      background: #5bcb95
      border: 2px solid #5bcb95
      color: #FFF
      i
        position: absolute
        left: 20px
        top: 15px
        font-size: 28px
      &:hover
        background: #38ad75
        border: 2px solid #38ad75
    button
      width: 100%
      padding: 10px 20px
      height: auto
      font-size: 24px
      position: relative
  .obs
    color: #656d78
    font-size: 12px
</style>
